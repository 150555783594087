import { template as template_448be83578b743fea4fd20c1bb0c8569 } from "@ember/template-compiler";
const FKControlMenuContainer = template_448be83578b743fea4fd20c1bb0c8569(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
